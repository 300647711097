import { useState, useEffect, useRef, useCallback } from "react";

// icons
import {
  IoPlayBackSharp,
  IoPlayForwardSharp,
  IoPlaySkipBackSharp,
  IoPlaySkipForwardSharp,
  IoPlaySharp,
  IoPauseSharp,
} from "react-icons/io5";

import { IoMdVolumeHigh, IoMdVolumeOff, IoMdVolumeLow } from "react-icons/io";

const Controls = ({
  audioRef,
  progressBarRef,
  duration,
  setTimeProgress,
  tracks,
  trackIndex,
  setTrackIndex,
  setCurrentTrack,
  handleNext,
  setPlayerPlaying,
  playerPlaying,
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(100);
  const [muteVolume, setMuteVolume] = useState(false);

  const togglePlayPause = () => {
    let p = (prev) => !prev;
    setIsPlaying(p);
    setPlayerPlaying(p);
  };

  const playAnimationRef = useRef();

  const repeat = useCallback(() => {
    const currentTime = audioRef.current.currentTime;
    setTimeProgress(currentTime);
    progressBarRef.current.value = currentTime;
    progressBarRef.current.style.setProperty(
      "--range-progress",
      `${(progressBarRef.current.value / duration) * 100}%`
    );

    playAnimationRef.current = requestAnimationFrame(repeat);
  }, [audioRef, duration, progressBarRef, setTimeProgress]);

  useEffect(() => {
    if (isPlaying) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }
    playAnimationRef.current = requestAnimationFrame(repeat);
  }, [isPlaying, playerPlaying, audioRef, repeat]);

  useEffect(() => {
    if (playerPlaying !== isPlaying) {
      setIsPlaying(playerPlaying);
    }
  }, [isPlaying, playerPlaying, audioRef, repeat]);

  const skipForward = () => {
    audioRef.current.currentTime += 15;
  };

  const skipBackward = () => {
    audioRef.current.currentTime -= 15;
  };

  const handlePrevious = () => {
    if (trackIndex === 0) {
      let lastTrackIndex = tracks.length - 1;
      setTrackIndex(lastTrackIndex);
      setCurrentTrack(tracks[lastTrackIndex]);
    } else {
      setTrackIndex((prev) => prev - 1);
      setCurrentTrack(tracks[trackIndex - 1]);
    }
  };

  useEffect(() => {
    if (audioRef) {
      audioRef.current.volume = volume / 100;
      audioRef.current.muted = muteVolume;
    }
  }, [volume, audioRef, muteVolume]);

  return (
    <div className="controls-wrapper">
      <div className="controls">
        {playerPlaying ? (
          <button onClick={handlePrevious}>
            <IoPlaySkipBackSharp />
          </button>
        ) : (
          <></>
        )}

        <button onClick={togglePlayPause}>
          {isPlaying ? (
            <>
              <svg
                width="70"
                height="70"
                viewBox="0 0 70 70"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_31_659)">
                  <path
                    d="M35.4453 0.627441C16.3923 0.627441 0.945312 16.0717 0.945312 35.1275C0.945312 54.1804 16.3923 69.6275 35.4453 69.6275C54.5011 69.6275 69.9453 54.1804 69.9453 35.1275C69.9453 16.0717 54.5011 0.627441 35.4453 0.627441ZM30.2569 46.1109C30.2569 47.003 29.5345 47.7281 28.6396 47.7281C27.7475 47.7281 27.0224 47.003 27.0224 46.1109V24.144C27.0224 23.2492 27.7475 22.5268 28.6396 22.5268C29.5345 22.5268 30.2569 23.2492 30.2569 24.144V46.1109ZM43.8685 46.1109C43.8685 47.003 43.1461 47.7281 42.2513 47.7281C41.3591 47.7281 40.6341 47.003 40.6341 46.1109V24.144C40.6341 23.2492 41.3591 22.5268 42.2513 22.5268C43.1461 22.5268 43.8685 23.2492 43.8685 24.144V46.1109Z"
                    fill="#EAEAEA"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_31_659">
                    <rect
                      width="69"
                      height="69"
                      fill="white"
                      transform="translate(0.945312 0.627441)"
                    />
                  </clipPath>
                  <linearGradient
                    id="MyGradient"
                    gradientTransform="rotate(0 0.5 0.5) scale(2)"
                  >
                    <stop offset="0%" stopColor="#e5eb1c" />
                    <stop offset="22%" stopColor="#1ec766" />
                    <stop offset="33%" stopColor="#f57f36" />
                    <stop offset="44%" stopColor="#6d81f5" />
                    <stop offset="55%" stopColor="#f68fc0" />
                    <stop offset="66%" stopColor="#f55958" />
                    <stop offset="90%" stopColor="#96c6f5" />
                    <stop offset="100%" stopColor="#d54db9" />

                    <animateTransform
                      attributeName="gradientTransform"
                      type="rotate"
                      values="0 0.5 0.5; 360 0.5 0.5"
                      dur="5s"
                      repeatCount="indefinite"
                      additive="sum"
                      accumulate="sum"
                    />

                    <animateTransform
                      attributeName="gradientTransform"
                      type="scale"
                      values="2; 3"
                      dur="5s"
                      repeatCount="indefinite"
                      additive="sum"
                      accumulate="sum"
                    />
                  </linearGradient>
                </defs>
              </svg>
            </>
          ) : (
            <>
              <svg
                width="69"
                height="69"
                viewBox="0 0 69 69"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_31_704)">
                  <path
                    d="M34.5 0C15.4462 0 0 15.4427 0 34.5C0 53.5573 15.4462 69 34.5 69C53.5538 69 69 53.5538 69 34.5C69 15.4462 53.5573 0 34.5 0ZM27.4739 45.6801V23.3234L47.3298 34.5035L27.4739 45.6801Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_31_704">
                    <rect width="69" height="69" fill="white" />
                  </clipPath>
                  <linearGradient
                    id="MyGradient"
                    gradientTransform="rotate(0 0.5 0.5) scale(2)"
                  >
                    <stop offset="0%" stopColor="#e5eb1c" />
                    <stop offset="22%" stopColor="#1ec766" />
                    <stop offset="33%" stopColor="#f57f36" />
                    <stop offset="44%" stopColor="#6d81f5" />
                    <stop offset="55%" stopColor="#f68fc0" />
                    <stop offset="66%" stopColor="#f55958" />
                    <stop offset="90%" stopColor="#96c6f5" />
                    <stop offset="100%" stopColor="#d54db9" />

                    <animateTransform
                      attributeName="gradientTransform"
                      type="rotate"
                      values="0 0.5 0.5; 360 0.5 0.5"
                      dur="5s"
                      repeatCount="indefinite"
                      additive="sum"
                      accumulate="sum"
                    />

                    <animateTransform
                      attributeName="gradientTransform"
                      type="scale"
                      values="2; 3"
                      dur="5s"
                      repeatCount="indefinite"
                      additive="sum"
                      accumulate="sum"
                    />
                  </linearGradient>
                </defs>
              </svg>
            </>
          )}
        </button>

        {playerPlaying ? (
          <button onClick={handleNext}>
            <IoPlaySkipForwardSharp />
          </button>
        ) : (
          <></>
        )}
      </div>
      <div className="volume">
        <button onClick={() => setMuteVolume((prev) => !prev)}>
          {muteVolume || volume < 5 ? (
            <IoMdVolumeOff />
          ) : volume < 40 ? (
            <IoMdVolumeLow />
          ) : (
            <IoMdVolumeHigh />
          )}
        </button>
        <input
          type="range"
          min={0}
          max={100}
          value={volume}
          onChange={(e) => setVolume(e.target.value)}
          style={{
            background: `linear-gradient(to right, #f50 ${volume}%, #ccc ${volume}%)`,
          }}
        />
      </div>
    </div>
  );
};

export default Controls;
