import { useState, useEffect } from "react";

const TopBar = ({ playerPlaying }) => {
  const [plays, setPlays] = useState(2934344);
  const [formattedPlays, setFormattedPlays] = useState("0");

  useEffect(() => {
    setTimeout(() => {
      let c = plays + 1;
      setPlays(c);
      const formattedNumber = c.toLocaleString("en-US");
      setFormattedPlays(formattedNumber);
    }, 1000);
  }, [plays, setPlays, setFormattedPlays]);

  return playerPlaying ? (
    <div className="top__bar"></div>
  ) : (
    <div className="top__bar">
      <svg
        width="594"
        height="285"
        viewBox="0 0 594 285"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M82.5608 40.9336V98.6791C82.5608 119.677 67.4522 130.561 44.2772 130.561C19.5657 130.561 6.50569 118.525 4.45707 97.0146L21.2302 94.8379C23.0227 108.154 30.0648 115.964 44.2772 115.964C56.825 115.964 64.6353 109.818 64.6353 98.1669V55.53H27.248V40.9336H82.5608Z"
          fill="white"
        />
        <path
          d="M136.365 130.561C107.172 130.561 87.7103 112.379 87.7103 85.107C87.7103 57.0665 107.172 38.3728 136.365 38.3728C165.558 38.3728 185.02 57.0665 185.02 85.107C185.02 112.379 165.558 130.561 136.365 130.561ZM136.365 115.708C154.675 115.708 166.838 103.417 166.838 85.107C166.838 66.0292 154.675 53.2253 136.365 53.2253C118.056 53.2253 105.892 66.0292 105.892 85.107C105.892 103.417 118.056 115.708 136.365 115.708Z"
          fill="white"
        />
        <path
          d="M222.488 25.4409C219.543 25.4409 218.135 27.3615 217.623 32.0989L204.051 29.9222C204.307 19.6791 210.837 13.0211 219.799 13.0211C229.402 13.0211 232.219 19.5511 237.213 19.5511C240.158 19.5511 241.438 17.6305 241.95 12.8931L255.522 15.0697C255.266 25.3128 248.736 31.9709 239.773 31.9709C230.171 31.9709 227.482 25.4409 222.488 25.4409ZM256.419 128L248.992 109.69H210.581L203.154 128H183.692L220.568 40.9336H239.005L275.88 128H256.419ZM216.47 95.094H243.102L229.786 61.9319L216.47 95.094Z"
          fill="white"
        />
        <path
          d="M317.935 130.561C288.742 130.561 269.28 112.379 269.28 85.107C269.28 57.0665 288.742 38.3728 317.935 38.3728C347.128 38.3728 366.59 57.0665 366.59 85.107C366.59 112.379 347.128 130.561 317.935 130.561ZM317.935 115.708C336.245 115.708 348.408 103.417 348.408 85.107C348.408 66.0292 336.245 53.2253 317.935 53.2253C299.626 53.2253 287.462 66.0292 287.462 85.107C287.462 103.417 299.626 115.708 317.935 115.708Z"
          fill="white"
        />
        <path d="M373.237 40.9336H391.162V128H373.237V40.9336Z" fill="white" />
        <path
          d="M442.014 130.561C422.168 130.561 400.657 123.263 397.456 102.392L414.998 98.8071C418.711 113.019 432.411 115.196 441.758 115.196C457.891 115.196 463.78 109.69 463.78 102.264C463.78 93.6856 454.049 92.9173 436.764 90.6126C421.912 88.6921 400.785 85.4911 400.785 65.389C400.785 47.3355 417.174 38.3728 440.349 38.3728C462.628 38.3728 477.353 47.5916 481.834 64.7488L464.293 68.3339C459.427 55.0178 447.391 53.4814 440.093 53.4814C428.826 53.4814 418.967 56.2982 418.967 64.3647C418.967 69.9984 424.216 72.8153 443.678 75.376C458.275 77.2966 481.962 78.9611 481.962 101.752C481.962 120.574 467.237 130.561 442.014 130.561Z"
          fill="white"
        />
        <path
          d="M565.104 40.9336H584.566V128H566.641V67.9498L546.026 98.5511H527.077L506.462 67.9498V128H488.537V40.9336H507.999L536.552 84.5948L565.104 40.9336Z"
          fill="white"
        />
        <path
          d="M210.18 160.4H212.98V174H209.9L202.26 164.34V174H199.46V160.4H202.54L210.18 170.06V160.4Z"
          fill="white"
        />
        <path
          d="M221.605 174.4C217.045 174.4 214.005 171.56 214.005 167.3C214.005 162.92 217.045 160 221.605 160C226.165 160 229.205 162.92 229.205 167.3C229.205 171.56 226.165 174.4 221.605 174.4ZM221.605 172.08C224.465 172.08 226.365 170.16 226.365 167.3C226.365 164.32 224.465 162.32 221.605 162.32C218.745 162.32 216.845 164.32 216.845 167.3C216.845 170.16 218.745 172.08 221.605 172.08Z"
          fill="white"
        />
        <path
          d="M236.644 174.4C232.824 174.4 230.044 172.18 230.044 168.2V160.4H232.744V167.74C232.744 170.24 233.804 172.12 236.644 172.12C239.484 172.12 240.544 170.24 240.544 167.74V160.4H243.244V168.2C243.244 172.18 240.464 174.4 236.644 174.4Z"
          fill="white"
        />
        <path
          d="M255.486 160.4H258.286V174H255.206L247.566 164.34V174H244.766V160.4H247.846L255.486 170.06V160.4Z"
          fill="white"
        />
        <path
          d="M268.012 158.18L269.212 159.04C266.692 162 265.492 164.7 265.492 167.6C265.492 170.5 266.692 173.2 269.212 176.16L268.012 177.02C265.192 173.84 263.892 170.88 263.892 167.6C263.892 164.32 265.192 161.36 268.012 158.18Z"
          fill="white"
        />
        <path
          d="M276.248 174.4C271.908 174.4 269.028 171.56 269.028 167.3C269.028 162.92 271.908 160 276.248 160C280.588 160 283.468 162.92 283.468 167.3C283.468 171.56 280.588 174.4 276.248 174.4ZM276.248 172.94C279.548 172.94 281.768 170.68 281.768 167.3C281.768 163.8 279.548 161.44 276.248 161.44C272.928 161.44 270.728 163.8 270.728 167.3C270.728 170.68 272.928 172.94 276.248 172.94Z"
          fill="white"
        />
        <path
          d="M295.011 174L291.511 168.42H286.771V174H285.091V160.4H291.971C294.971 160.4 296.711 162.08 296.711 164.42C296.711 166.34 295.471 167.86 293.311 168.28L296.891 174H295.011ZM286.771 161.84V166.98H291.991C294.091 166.98 295.011 165.74 295.011 164.42C295.011 163.02 294.091 161.84 291.991 161.84H286.771Z"
          fill="white"
        />
        <path
          d="M310.815 160.4H312.735L306.875 174H305.015L299.155 160.4H301.075L305.955 172.26L310.815 160.4Z"
          fill="white"
        />
        <path
          d="M324.905 161.84H315.105V165.98H323.165V167.42H315.105V172.56H324.905V174H313.425V160.4H324.905V161.84Z"
          fill="white"
        />
        <path
          d="M336.305 174L332.805 168.42H328.065V174H326.385V160.4H333.265C336.265 160.4 338.005 162.08 338.005 164.42C338.005 166.34 336.765 167.86 334.605 168.28L338.185 174H336.305ZM328.065 161.84V166.98H333.285C335.385 166.98 336.305 165.74 336.305 164.42C336.305 163.02 335.385 161.84 333.285 161.84H328.065Z"
          fill="white"
        />
        <path
          d="M348.33 166.56C349.95 166.94 351.03 168.06 351.03 169.98C351.03 172.62 349.09 174 345.81 174H339.13V160.4H346.15C348.65 160.4 350.29 161.76 350.29 163.7C350.29 165 349.53 166.08 348.33 166.56ZM345.99 161.84H340.81V165.94H345.93C347.79 165.94 348.63 164.84 348.63 163.76C348.63 162.6 347.75 161.84 345.99 161.84ZM345.75 172.56C347.93 172.56 349.39 171.76 349.39 169.88C349.39 168.14 348.01 167.34 345.95 167.34H340.81V172.56H345.75Z"
          fill="white"
        />
        <path
          d="M356.033 170.6V170.16C356.033 165.3 360.953 166.74 360.953 163.84C360.953 162.02 359.013 161.44 357.513 161.44C355.673 161.44 354.153 162.28 353.273 164.14L351.813 163.48C352.513 161.84 354.353 160 357.573 160C360.293 160 362.613 161.3 362.613 163.88C362.613 167.66 357.693 166.44 357.693 169.96V170.6H356.033ZM355.853 174V172.02H357.873V174H355.853Z"
          fill="white"
        />
        <path
          d="M363.745 158.18C366.565 161.36 367.865 164.32 367.865 167.6C367.865 170.88 366.565 173.84 363.745 177.02L362.545 176.16C365.085 173.2 366.265 170.5 366.265 167.6C366.265 164.7 365.085 162 362.545 159.04L363.745 158.18Z"
          fill="white"
        />
        <path
          d="M9.32002 160.56H5.52002V174.64H9.32002V177H2.92002V158.2H9.32002V160.56Z"
          fill="white"
        />
        <path
          d="M24.7775 160.4V169.42C24.7775 172.7 22.4175 174.4 18.7975 174.4C14.9375 174.4 12.8975 172.52 12.5775 169.16L15.1975 168.82C15.4775 170.9 16.5775 172.12 18.7975 172.12C20.7575 172.12 21.9775 171.16 21.9775 169.34V162.68H16.1375V160.4H24.7775Z"
          fill="white"
        />
        <path
          d="M33.1819 174.4C28.6219 174.4 25.5819 171.56 25.5819 167.3C25.5819 162.92 28.6219 160 33.1819 160C37.7419 160 40.7819 162.92 40.7819 167.3C40.7819 171.56 37.7419 174.4 33.1819 174.4ZM33.1819 172.08C36.0419 172.08 37.9419 170.16 37.9419 167.3C37.9419 164.32 36.0419 162.32 33.1819 162.32C30.3219 162.32 28.4219 164.32 28.4219 167.3C28.4219 170.16 30.3219 172.08 33.1819 172.08Z"
          fill="white"
        />
        <path d="M41.6122 167.1H48.4122V169.3H41.6122V167.1Z" fill="white" />
        <path
          d="M56.7975 174.4C52.2375 174.4 49.1975 171.56 49.1975 167.3C49.1975 162.92 52.2375 160 56.7975 160C61.3575 160 64.3975 162.92 64.3975 167.3C64.3975 171.56 61.3575 174.4 56.7975 174.4ZM56.7975 172.08C59.6575 172.08 61.5575 170.16 61.5575 167.3C61.5575 164.32 59.6575 162.32 56.7975 162.32C53.9375 162.32 52.0375 164.32 52.0375 167.3C52.0375 170.16 53.9375 172.08 56.7975 172.08Z"
          fill="white"
        />
        <path
          d="M80.3624 160.4H83.4024L79.6624 174H76.4624L73.6024 164.84L70.7424 174H67.5424L63.8024 160.4H66.8424L69.2224 170.46L72.2224 160.4H74.9824L77.9824 170.46L80.3624 160.4Z"
          fill="white"
        />
        <path d="M82.9146 167.1H89.7145V169.3H82.9146V167.1Z" fill="white" />
        <path d="M90.8678 160.4H93.6678V174H90.8678V160.4Z" fill="white" />
        <path
          d="M94.391 174V172.08L102.951 162.68H94.5509V160.4H106.591V162.32L98.031 171.72H106.791V174H94.391Z"
          fill="white"
        />
        <path d="M106.736 167.1H113.536V169.3H106.736V167.1Z" fill="white" />
        <path
          d="M121.09 174.4C117.27 174.4 114.49 172.18 114.49 168.2V160.4H117.19V167.74C117.19 170.24 118.25 172.12 121.09 172.12C123.93 172.12 124.99 170.24 124.99 167.74V160.4H127.69V168.2C127.69 172.18 124.91 174.4 121.09 174.4Z"
          fill="white"
        />
        <path
          d="M140.012 160.4H142.812V174H140.012V167.74H132.012V174H129.212V160.4H132.012V165.46H140.012V160.4Z"
          fill="white"
        />
        <path
          d="M156.496 160.4H159.536V174H156.736V164.62L153.516 169.4H150.556L147.336 164.62V174H144.536V160.4H147.576L152.036 167.22L156.496 160.4Z"
          fill="white"
        />
        <path
          d="M169.865 158.2V177H163.465V174.64H167.265V160.56H163.465V158.2H169.865Z"
          fill="white"
        />
        <path
          d="M19.144 241L17.288 236.424H7.68802L5.83202 241H0.968018L10.184 219.24H14.792L24.008 241H19.144ZM9.16002 232.776H15.816L12.488 224.488L9.16002 232.776Z"
          fill="white"
        />
        <path
          d="M47.6168 219.24H52.0968V241H47.1688L34.9448 225.544V241H30.4648V219.24H35.3928L47.6168 234.696V219.24Z"
          fill="white"
        />
        <path
          d="M65.0973 241.64C58.9853 241.64 54.5373 238.088 54.5373 231.72V219.24H58.8573V230.984C58.8573 234.984 60.5533 237.992 65.0973 237.992C69.6413 237.992 71.3373 234.984 71.3373 230.984V219.24H75.6573V231.72C75.6573 238.088 71.2093 241.64 65.0973 241.64Z"
          fill="white"
        />
        <path
          d="M99.6615 229.192V241H96.3655L96.0455 237.8C94.7015 239.944 92.3335 241.64 88.3015 241.64C81.5815 241.64 76.9735 237.512 76.9735 230.344C76.9735 223.56 81.6775 218.6 88.8135 218.6C93.8695 218.6 97.2615 220.648 99.4055 224.584L95.5655 226.312C94.0615 223.56 92.1415 222.248 89.0055 222.248C84.3335 222.248 81.5815 225.672 81.5815 230.344C81.5815 235.112 84.3655 237.992 88.7175 237.992C92.6855 237.992 94.6695 235.688 95.1815 232.712H87.0535V229.192H99.6615Z"
          fill="white"
        />
        <path
          d="M123.804 229.192V241H120.508L120.188 237.8C118.844 239.944 116.476 241.64 112.444 241.64C105.724 241.64 101.116 237.512 101.116 230.344C101.116 223.56 105.82 218.6 112.956 218.6C118.012 218.6 121.404 220.648 123.548 224.584L119.708 226.312C118.204 223.56 116.284 222.248 113.148 222.248C108.476 222.248 105.724 225.672 105.724 230.344C105.724 235.112 108.508 237.992 112.86 237.992C116.828 237.992 118.812 235.688 119.324 232.712H111.196V229.192H123.804Z"
          fill="white"
        />
        <path
          d="M145.579 222.888H130.859V227.816H142.955V231.464H130.859V237.352H145.579V241H126.379V219.24H145.579V222.888Z"
          fill="white"
        />
        <path
          d="M167.663 219.24V222.888H158.703V241H154.223V222.888H145.263V219.24H167.663Z"
          fill="white"
        />
        <path
          d="M184.252 241.64C176.956 241.64 172.092 237.096 172.092 230.28C172.092 223.272 176.956 218.6 184.252 218.6C191.548 218.6 196.412 223.272 196.412 230.28C196.412 237.096 191.548 241.64 184.252 241.64ZM184.252 237.928C188.828 237.928 191.868 234.856 191.868 230.28C191.868 225.512 188.828 222.312 184.252 222.312C179.676 222.312 176.636 225.512 176.636 230.28C176.636 234.856 179.676 237.928 184.252 237.928Z"
          fill="white"
        />
        <path
          d="M217.274 222.888H202.554V227.848H214.65V231.496H202.554V241H198.074V219.24H217.274V222.888Z"
          fill="white"
        />
        <path
          d="M245.079 233.736C243.735 238.568 239.607 241.64 233.367 241.64C226.071 241.64 221.207 237.096 221.207 230.28C221.207 223.272 226.071 218.6 233.367 218.6C239.447 218.6 243.575 221.576 244.951 226.344L240.567 227.4C239.543 224.264 237.207 222.312 233.367 222.312C228.791 222.312 225.751 225.512 225.751 230.28C225.751 234.856 228.791 237.928 233.367 237.928C237.271 237.928 239.639 235.976 240.631 232.84L245.079 233.736Z"
          fill="white"
        />
        <path
          d="M261.159 241L256.391 233.192H250.919V241H246.439V219.24H257.319C262.887 219.24 265.863 221.8 265.863 226.216C265.863 229.224 264.231 231.592 261.159 232.616L266.342 241H261.159ZM250.919 222.888V229.544H257.543C259.847 229.544 261.319 228.04 261.319 226.216C261.319 224.04 259.847 222.888 257.543 222.888H250.919Z"
          fill="white"
        />
        <path
          d="M286.812 222.888H272.092V227.816H284.188V231.464H272.092V237.352H286.812V241H267.612V219.24H286.812V222.888Z"
          fill="white"
        />
        <path
          d="M304.313 241L302.457 236.424H292.857L291.001 241H286.137L295.353 219.24H299.961L309.177 241H304.313ZM294.329 232.776H300.985L297.657 224.488L294.329 232.776Z"
          fill="white"
        />
        <path
          d="M328.476 219.24V222.888H319.516V241H315.036V222.888H306.076V219.24H328.476Z"
          fill="white"
        />
        <path d="M329.54 219.24H334.02V241H329.54V219.24Z" fill="white" />
        <path
          d="M352.297 219.24H357.289L348.073 241H343.465L334.249 219.24H339.241L345.769 236.04L352.297 219.24Z"
          fill="white"
        />
        <path
          d="M376.743 222.888H362.023V227.816H374.12V231.464H362.023V237.352H376.743V241H357.544V219.24H376.743V222.888Z"
          fill="white"
        />
        <path
          d="M27.656 257.24H32.52L26.536 279H21.416L16.84 264.344L12.264 279H7.14402L1.16002 257.24H6.02402L9.83202 273.336L14.632 257.24H19.048L23.848 273.336L27.656 257.24Z"
          fill="white"
        />
        <path d="M33.1035 257.24H37.5835V279H33.1035V257.24Z" fill="white" />
        <path
          d="M50.2925 279.64C45.3325 279.64 39.9565 277.816 39.1565 272.6L43.5405 271.704C44.4685 275.256 47.8925 275.8 50.2285 275.8C54.2605 275.8 55.7325 274.424 55.7325 272.568C55.7325 270.424 53.3005 270.232 48.9805 269.656C45.2685 269.176 39.9885 268.376 39.9885 263.352C39.9885 258.84 44.0845 256.6 49.8765 256.6C55.4445 256.6 59.1245 258.904 60.2445 263.192L55.8605 264.088C54.6445 260.76 51.6365 260.376 49.8125 260.376C46.9965 260.376 44.5325 261.08 44.5325 263.096C44.5325 264.504 45.8445 265.208 50.7085 265.848C54.3565 266.328 60.2765 266.744 60.2765 272.44C60.2765 277.144 56.5965 279.64 50.2925 279.64Z"
          fill="white"
        />
        <path
          d="M72.2238 257.24C79.3598 257.24 83.6798 261.592 83.6798 268.12C83.6798 274.648 79.3598 279 72.2238 279H61.9198V257.24H72.2238ZM72.2238 275.352C75.6478 275.352 79.1358 273.528 79.1358 268.12C79.1358 262.712 75.6478 260.888 72.2238 260.888H66.3998V275.352H72.2238Z"
          fill="white"
        />
        <path
          d="M96.3835 279.64C89.0875 279.64 84.2235 275.096 84.2235 268.28C84.2235 261.272 89.0875 256.6 96.3835 256.6C103.68 256.6 108.544 261.272 108.544 268.28C108.544 275.096 103.68 279.64 96.3835 279.64ZM96.3835 275.928C100.96 275.928 104 272.856 104 268.28C104 263.512 100.96 260.312 96.3835 260.312C91.8075 260.312 88.7675 263.512 88.7675 268.28C88.7675 272.856 91.8075 275.928 96.3835 275.928Z"
          fill="white"
        />
        <path
          d="M129.341 257.24H134.205V279H129.725V263.992L124.573 271.64H119.837L114.685 263.992V279H110.205V257.24H115.069L122.205 268.152L129.341 257.24Z"
          fill="white"
        />
        <path
          d="M162.252 260.888H147.532V265.848H159.628V269.496H147.532V279H143.052V257.24H162.252V260.888Z"
          fill="white"
        />
        <path
          d="M178.196 279L173.428 271.192H167.956V279H163.476V257.24H174.356C179.924 257.24 182.9 259.8 182.9 264.216C182.9 267.224 181.268 269.592 178.196 270.616L183.38 279H178.196ZM167.956 260.888V267.544H174.58C176.884 267.544 178.356 266.04 178.356 264.216C178.356 262.04 176.884 260.888 174.58 260.888H167.956Z"
          fill="white"
        />
        <path
          d="M195.659 279.64C188.363 279.64 183.499 275.096 183.499 268.28C183.499 261.272 188.363 256.6 195.659 256.6C202.955 256.6 207.819 261.272 207.819 268.28C207.819 275.096 202.955 279.64 195.659 279.64ZM195.659 275.928C200.235 275.928 203.275 272.856 203.275 268.28C203.275 263.512 200.235 260.312 195.659 260.312C191.083 260.312 188.043 263.512 188.043 268.28C188.043 272.856 191.083 275.928 195.659 275.928Z"
          fill="white"
        />
        <path
          d="M228.616 257.24H233.48V279H229V263.992L223.848 271.64H219.112L213.96 263.992V279H209.48V257.24H214.344L221.48 268.152L228.616 257.24Z"
          fill="white"
        />
        <path
          d="M259.75 257.24V271.672C259.75 276.92 255.974 279.64 250.182 279.64C244.006 279.64 240.742 276.632 240.23 271.256L244.422 270.712C244.87 274.04 246.63 275.992 250.182 275.992C253.318 275.992 255.27 274.456 255.27 271.544V260.888H245.926V257.24H259.75Z"
          fill="white"
        />
        <path
          d="M273.197 279.64C265.901 279.64 261.037 275.096 261.037 268.28C261.037 261.272 265.901 256.6 273.197 256.6C280.493 256.6 285.357 261.272 285.357 268.28C285.357 275.096 280.493 279.64 273.197 279.64ZM273.197 275.928C277.773 275.928 280.813 272.856 280.813 268.28C280.813 263.512 277.773 260.312 273.197 260.312C268.621 260.312 265.581 263.512 265.581 268.28C265.581 272.856 268.621 275.928 273.197 275.928Z"
          fill="white"
        />
        <path
          d="M294.722 253.368C293.986 253.368 293.634 253.848 293.506 255.032L290.114 254.488C290.178 251.928 291.809 250.264 294.049 250.264C296.449 250.264 297.154 251.896 298.402 251.896C299.138 251.896 299.458 251.416 299.586 250.232L302.978 250.776C302.914 253.336 301.282 255 299.042 255C296.642 255 295.97 253.368 294.722 253.368ZM303.202 279L301.346 274.424H291.746L289.89 279H285.026L294.242 257.24H298.85L308.066 279H303.202ZM293.217 270.776H299.874L296.546 262.488L293.217 270.776Z"
          fill="white"
        />
        <path
          d="M318.576 279.64C311.28 279.64 306.416 275.096 306.416 268.28C306.416 261.272 311.28 256.6 318.576 256.6C325.872 256.6 330.736 261.272 330.736 268.28C330.736 275.096 325.872 279.64 318.576 279.64ZM318.576 275.928C323.152 275.928 326.192 272.856 326.192 268.28C326.192 263.512 323.152 260.312 318.576 260.312C314 260.312 310.96 263.512 310.96 268.28C310.96 272.856 314 275.928 318.576 275.928Z"
          fill="white"
        />
        <path
          d="M354.021 267.16C356.229 267.832 357.669 269.56 357.669 272.44C357.669 276.664 354.949 279 349.317 279H337.509V257.24H349.669C353.573 257.24 356.389 259.736 356.389 262.872C356.389 264.76 355.461 266.36 354.021 267.16ZM348.933 260.888H341.989V265.688H348.517C350.757 265.688 352.037 264.792 352.037 263.192C352.037 261.784 350.981 260.888 348.933 260.888ZM349.189 275.352C351.653 275.352 353.317 274.424 353.317 272.184C353.317 270.136 351.877 269.112 349.445 269.112H341.989V275.352H349.189Z"
          fill="white"
        />
        <path
          d="M374.121 279L369.353 271.192H363.881V279H359.401V257.24H370.281C375.849 257.24 378.825 259.8 378.825 264.216C378.825 267.224 377.193 269.592 374.121 270.616L379.305 279H374.121ZM363.881 260.888V267.544H370.505C372.809 267.544 374.281 266.04 374.281 264.216C374.281 262.04 372.809 260.888 370.505 260.888H363.881Z"
          fill="white"
        />
        <path
          d="M396.445 279L394.589 274.424H384.989L383.133 279H378.269L387.485 257.24H392.093L401.309 279H396.445ZM386.461 270.776H393.117L389.789 262.488L386.461 270.776Z"
          fill="white"
        />
        <path
          d="M422.348 267.192V279H419.052L418.732 275.8C417.388 277.944 415.02 279.64 410.988 279.64C404.268 279.64 399.66 275.512 399.66 268.344C399.66 261.56 404.364 256.6 411.5 256.6C416.556 256.6 419.948 258.648 422.092 262.584L418.252 264.312C416.748 261.56 414.828 260.248 411.692 260.248C407.02 260.248 404.268 263.672 404.268 268.344C404.268 273.112 407.052 275.992 411.404 275.992C415.372 275.992 417.356 273.688 417.868 270.712H409.74V267.192H422.348Z"
          fill="white"
        />
        <path
          d="M440.73 279L438.874 274.424H429.274L427.418 279H422.554L431.77 257.24H436.378L445.594 279H440.73ZM430.746 270.776H437.402L434.074 262.488L430.746 270.776Z"
          fill="white"
        />
        <path d="M445.85 274.648H450.266V279H445.85V274.648Z" fill="white" />
      </svg>

      <div>
        <p>
          João is an enigma, a spirit, a mindset. As such, he can be hard to pin
          down. So, when you need a quick creative steer, come here. Get 20+
          years of global, award-winning wisdom, at the click of a button. Then
          see you in Cannes, my friend!
        </p>
      </div>
    </div>
  );
};

export default TopBar;
