// audio files
import silence from "./silence.mp3";
import joao1 from "./joao1.mp3";
import joao2 from "./joao2.mp3";
import joao3 from "./joao3.mp3";
import joao4 from "./joao4.mp3";
import joao5 from "./joao5.mp3";
import joao6 from "./joao6.mp3";
import joao7 from "./joao7.mp3";
import joao8 from "./joao8.mp3";
import joao9 from "./joao9.mp3";
import joao10 from "./joao10.mp3";
import joao11 from "./joao11.mp3";
import joao12 from "./joao12.mp3";
import joao13 from "./joao13.mp3";
import joao14 from "./joao14.mp3";
import joao15 from "./joao15.mp3";
import joao16 from "./joao16.mp3";
import joao17 from "./joao17.mp3";
import joao18 from "./joao18.mp3";
import joao19 from "./joao19.mp3";
import joao20 from "./joao20.mp3";
import joao21 from "./joao21.mp3";
import joao22 from "./joao22.mp3";
import joao23 from "./joao23.mp3";
import joao24 from "./joao24.mp3";
import joao25 from "./joao25.mp3";
import joao26 from "./joao26.mp3";
import joao27 from "./joao27.mp3";
import joao28 from "./joao28.mp3";
import joao29 from "./joao29.mp3";
import joao30 from "./joao30.mp3";

import gong from "./gong.mp3";
import chime from "./chime.mp3";
import song from "./song.mp3";

// audio thumbnails

// import cover from "./joao3.gif";
// import wisdom4 from "./wisdom5.gif";
// import final from "./final.gif";

import cover from "./joao3.mp4";
import wisdom4 from "./wisdom7.mp4";
import final from "./final2.mp4";

import cover2 from "./joao3.webm";
import wisdom42 from "./wisdom7.webm";
import final2 from "./final.webm";

export const tracks = [
  {
    title: "",
    src: silence,
    author: "",
    thumbnail: cover,
    thumbnail2: cover2,
  },
  {
    title: "Joãoism #1",
    src: chime,
    author: "The core idea",
    thumbnail: cover,
    thumbnail2: cover2,
  },
  {
    title: "",
    src: joao1,
    author: "",
    thumbnail: cover,
    thumbnail2: cover2,
  },

  {
    title: "🤯",
    src: gong,
    author: "",
    thumbnail: wisdom4,
    thumbnail2: wisdom42,
  },
  {
    title: "Joãoism #2",
    src: chime,
    author: "Understand the problem",
    thumbnail: cover,
    thumbnail2: cover2,
  },
  {
    title: "",
    src: joao3,
    author: "",
    thumbnail: cover,
    thumbnail2: cover2,
  },

  {
    title: "🤯",
    src: gong,
    author: "",
    thumbnail: wisdom4,
    thumbnail2: wisdom42,
  },
  {
    title: "Joãoism #3",
    src: chime,
    author: "Keep it tropical",
    thumbnail: cover,
    thumbnail2: cover2,
  },
  {
    title: "",
    src: joao28,
    author: "",
    thumbnail: cover,
    thumbnail2: cover2,
  },

  {
    title: "🤯",
    src: gong,
    author: "",
    thumbnail: wisdom4,
    thumbnail2: wisdom42,
  },

  {
    title: "Joãoism #4",
    src: chime,
    author: "Blue sky thinking",
    thumbnail: cover,
    thumbnail2: cover2,
  },
  {
    title: "",
    src: joao29,
    author: "",
    thumbnail: cover,
    thumbnail2: cover2,
  },

  {
    title: "🤯",
    src: gong,
    author: "",
    thumbnail: wisdom4,
    thumbnail2: wisdom42,
  },

  {
    title: "Joãoism #5",
    src: chime,
    author: "Lawyers",
    thumbnail: cover,
    thumbnail2: cover2,
  },
  {
    title: "",
    src: joao30,
    author: "",
    thumbnail: cover,
    thumbnail2: cover2,
  },

  {
    title: "🤯",
    src: gong,
    author: "",
    thumbnail: wisdom4,
    thumbnail2: wisdom42,
  },

  {
    title: "Joãoism #6",
    src: chime,
    author: "Don't overthink it",
    thumbnail: cover,
    thumbnail2: cover2,
  },
  {
    title: "",
    src: joao5,
    author: "",
    thumbnail: cover,
    thumbnail2: cover2,
  },
  {
    title: "🤯",
    src: gong,
    author: "",
    thumbnail: wisdom4,
    thumbnail2: wisdom42,
  },
  {
    title: "Joãoism #7",
    src: chime,
    author: "Push for more",
    thumbnail: cover,
    thumbnail2: cover2,
  },
  {
    title: "",
    src: joao6,
    author: "",
    thumbnail: cover,
    thumbnail2: cover2,
  },
  {
    title: "🤯",
    src: gong,
    author: "",
    thumbnail: wisdom4,
    thumbnail2: wisdom42,
  },
  {
    title: "Joãoism #8",
    src: chime,
    author: "It is what it is",
    thumbnail: cover,
    thumbnail2: cover2,
  },
  {
    title: "",
    src: joao7,
    author: "",
    thumbnail: cover,
    thumbnail2: cover2,
  },
  {
    title: "🤯",
    src: gong,
    author: "",
    thumbnail: wisdom4,
    thumbnail2: wisdom42,
  },

  {
    title: "Joãoism #9",
    src: chime,
    author: "Space is cool",
    thumbnail: cover,
    thumbnail2: cover2,
  },
  {
    title: "",
    src: joao19,
    author: "",
    thumbnail: cover,
    thumbnail2: cover2,
  },

  {
    title: "🪐",
    src: gong,
    author: "",
    thumbnail: wisdom4,
    thumbnail2: wisdom42,
  },
  {
    title: "Joãoism #10",
    src: chime,
    author: "There's potential?",
    thumbnail: cover,
    thumbnail2: cover2,
  },
  {
    title: "",
    src: joao9,
    author: "",
    thumbnail: cover,
    thumbnail2: cover2,
  },
  {
    title: "🤯",
    src: gong,
    author: "",
    thumbnail: wisdom4,
    thumbnail2: wisdom42,
  },
  {
    title: "Joãoism #11",
    src: chime,
    author: "Ideas need the how",
    thumbnail: cover,
    thumbnail2: cover2,
  },
  {
    title: "",
    src: joao10,
    author: "",
    thumbnail: cover,
    thumbnail2: cover2,
  },
  {
    title: "🤯",
    src: gong,
    author: "",
    thumbnail: wisdom4,
    thumbnail2: wisdom42,
  },
  {
    title: "Joãoism #12",
    src: chime,
    author: "Pressure the brand",
    thumbnail: cover,
    thumbnail2: cover2,
  },
  {
    title: "",
    src: joao11,
    author: "",
    thumbnail: cover,
    thumbnail2: cover2,
  },
  {
    title: "🤯",
    src: gong,
    author: "",
    thumbnail: wisdom4,
    thumbnail2: wisdom42,
  },
  {
    title: "Joãoism #13",
    src: chime,
    author: "Safe vs. Sexy",
    thumbnail: cover,
    thumbnail2: cover2,
  },
  {
    title: "",
    src: joao12,
    author: "",
    thumbnail: cover,
    thumbnail2: cover2,
  },
  {
    title: "🤯",
    src: gong,
    author: "",
    thumbnail: wisdom4,
    thumbnail2: wisdom42,
  },
  {
    title: "Joãoism #14",
    src: chime,
    author: "Open up",
    thumbnail: cover,
    thumbnail2: cover2,
  },
  {
    title: "",
    src: joao13,
    author: "",
    thumbnail: cover,
    thumbnail2: cover2,
  },
  {
    title: "🤯",
    src: gong,
    author: "",
    thumbnail: wisdom4,
    thumbnail2: wisdom42,
  },
  {
    title: "Joãoism #15",
    src: chime,
    author: "Heart is everything",
    thumbnail: cover,
    thumbnail2: cover2,
  },
  {
    title: "",
    src: joao14,
    author: "",
    thumbnail: cover,
    thumbnail2: cover2,
  },
  {
    title: "🤯",
    src: gong,
    author: "",
    thumbnail: wisdom4,
    thumbnail2: wisdom42,
  },
  {
    title: "Joãoism #16",
    src: chime,
    author: "Is this a thing?",
    thumbnail: cover,
    thumbnail2: cover2,
  },
  {
    title: "",
    src: joao15,
    author: "",
    thumbnail: cover,
    thumbnail2: cover2,
  },
  {
    title: "🤯",
    src: gong,
    author: "",
    thumbnail: wisdom4,
    thumbnail2: wisdom42,
  },
  {
    title: "Joãoism #17",
    src: chime,
    author: "Again and again",
    thumbnail: cover,
    thumbnail2: cover2,
  },
  {
    title: "",
    src: joao16,
    author: "",
    thumbnail: cover,
    thumbnail2: cover2,
  },
  {
    title: "🤯",
    src: gong,
    author: "",
    thumbnail: wisdom4,
    thumbnail2: wisdom42,
  },
  {
    title: "Joãoism #18",
    src: chime,
    author: "Trust your gut",
    thumbnail: cover,
    thumbnail2: cover2,
  },
  {
    title: "",
    src: joao17,
    author: "",
    thumbnail: cover,
    thumbnail2: cover2,
  },
  {
    title: "🤯",
    src: gong,
    author: "",
    thumbnail: wisdom4,
    thumbnail2: wisdom42,
  },
  {
    title: "Joãoism #19",
    src: chime,
    author: "One slide",
    thumbnail: cover,
    thumbnail2: cover2,
  },
  {
    title: "",
    src: joao18,
    author: "",
    thumbnail: cover,
    thumbnail2: cover2,
  },
  {
    title: "🤯",
    src: gong,
    author: "",
    thumbnail: wisdom4,
    thumbnail2: wisdom42,
  },

  {
    title: "Joãoism #20",
    src: chime,
    author: "Greatness",
    thumbnail: cover,
    thumbnail2: cover2,
  },
  {
    title: "",
    src: joao20,
    author: "",
    thumbnail: cover,
    thumbnail2: cover2,
  },
  {
    title: "💪",
    src: gong,
    author: "",
    thumbnail: wisdom4,
    thumbnail2: wisdom42,
  },
  {
    title: "Joãoism #21",
    src: chime,
    author: "Beauty",
    thumbnail: cover,
    thumbnail2: cover2,
  },
  {
    title: "",
    src: joao27,
    author: "",
    thumbnail: cover,
    thumbnail2: cover2,
  },

  {
    title: "🤯",
    src: gong,
    author: "",
    thumbnail: wisdom4,
    thumbnail2: wisdom42,
  },

  {
    title: "Joãoism #22",
    src: chime,
    author: "Make it different",
    thumbnail: cover,
    thumbnail2: cover2,
  },
  {
    title: "",
    src: joao25,
    author: "",
    thumbnail: cover,
    thumbnail2: cover2,
  },
  {
    title: "🤯",
    src: gong,
    author: "",
    thumbnail: wisdom4,
    thumbnail2: wisdom42,
  },

  {
    title: "Joãoism #23",
    src: chime,
    author: "Meg and Harry Styles?",
    thumbnail: cover,
    thumbnail2: cover2,
  },
  {
    title: "",
    src: joao22,
    author: "",
    thumbnail: cover,
    thumbnail2: cover2,
  },
  {
    title: "💃🕺",
    src: gong,
    author: "",
    thumbnail: wisdom4,
    thumbnail2: wisdom42,
  },
  {
    title: "Joãoism #24",
    src: chime,
    author: "Grace... angry?",
    thumbnail: cover,
    thumbnail2: cover2,
  },
  {
    title: "",
    src: joao23,
    author: "",
    thumbnail: cover,
    thumbnail2: cover2,
  },
  {
    title: "🤬",
    src: gong,
    author: "",
    thumbnail: wisdom4,
    thumbnail2: wisdom42,
  },

  {
    title: "Joãoism #25",
    src: chime,
    author: "No more tequila",
    thumbnail: cover,
    thumbnail2: cover2,
  },
  {
    title: "",
    src: joao24,
    author: "",
    thumbnail: cover,
    thumbnail2: cover2,
  },
  {
    title: "🍹",
    src: gong,
    author: "",
    thumbnail: wisdom4,
    thumbnail2: wisdom42,
  },

  {
    title: "Joãoism #26",
    src: chime,
    author: "See you in Cannes",
    thumbnail: cover,
    thumbnail2: cover2,
  },
  {
    title: "",
    src: joao8,
    author: "",
    thumbnail: cover,
    thumbnail2: cover2,
  },

  {
    title: "See you there man!",
    src: song,
    author: "❤️ your mates @WT Oz",
    thumbnail: final,
    thumbnail2: final2,
  },
];
