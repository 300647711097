import { useState, useEffect } from "react";
import axios from "axios";

const numPlays = 7124;

const Marquee = ({ handleSecret }) => {
  const rndInt = Math.floor(Math.random() * 5) + 1;
  const [data, setData] = useState({ locations: [] });
  const [loading, setLoading] = useState(true);
  const [finished, setFinished] = useState(true);
  const [error, setError] = useState(null);

  const [plays, setPlays] = useState(numPlays);
  const [formattedPlays, setFormattedPlays] = useState(
    numPlays.toLocaleString("en-US")
  );

  const [listeners, setListeners] = useState(rndInt);

  useEffect(() => {}, [plays, setPlays, setFormattedPlays]);

  useEffect(() => {}, [
    setListeners,
    setData,
    setError,
    setLoading,
    setFinished,
  ]);

  return (
    <div
      className={finished ? "marquee animating" : "marquee"}
      onClick={handleSecret}
    >
      <div className="marquee__group">
        <span>
          THE FOLLOWING ARE SOME NUGGETS OF WISDOM WE RECEIVED FROM THE CREATIVE
          LEGEND - João - DURING HIS TIME AT WUNDERMAN THOMPSON AUSTRALIA -
          2021-2023 - WE LOVE YOU MATE -
        </span>
      </div>
      <div className="marquee__group">
        <span>
          THE FOLLOWING ARE SOME NUGGETS OF WISDOM WE RECEIVED FROM THE CREATIVE
          LEGEND - João - DURING HIS TIME AT WUNDERMAN THOMPSON AUSTRALIA -
          2021-2023 - WE LOVE YOU MATE -
        </span>
      </div>
    </div>
  );
};

export default Marquee;
