import { BsMusicNoteBeamed } from "react-icons/bs";
import { useRef, useEffect } from "react";

function Clip({ url, url2 }) {
  const videoRef = useRef();

  useEffect(() => {
    videoRef.current?.load();
  }, [url]);

  if (url.indexOf("wisdom") > -1) {
    return (
      <video
        ref={videoRef}
        autoPlay="autoplay"
        muted
        defaultMuted
        playsInline
        oncontextmenu="return false;"
        preload="auto"
        id="myVideo"
      >
        <source src={url} type="video/mp4" />
        <source src={url2} type="video/webm" />
      </video>
    );
  }

  if (url.indexOf("final") > -1) {
    return (
      <video
        ref={videoRef}
        autoPlay="autoplay"
        muted
        loop="loop"
        defaultMuted
        playsInline
        oncontextmenu="return false;"
        preload="auto"
        id="myVideo"
      >
        <source src={url} type="video/mp4" />
        <source src={url2} type="video/webm" />
      </video>
    );
  }

  return (
    <></>
    // <video
    //   ref={videoRef}
    //   autoPlay="autoplay"
    //   loop="loop"
    //   muted
    //   defaultMuted
    //   playsInline
    //   oncontextmenu="return false;"
    //   preload="auto"
    //   id="myVideo"
    // >
    //   <source src={url} type="video/mp4" />
    //   <source src={url2} type="video/webm" />
    // </video>
  );
}

const DisplayTrack = ({
  currentTrack,
  audioRef,
  setDuration,
  progressBarRef,
  handleNext,
  handleToggleRequests,
  playerPlaying,
}) => {
  const onLoadedMetadata = () => {
    const seconds = audioRef.current.duration;
    setDuration(seconds);
    progressBarRef.current.max = seconds;
  };

  const handleRequest = () => {
    alert("Coming soon! We promise. Try coming back tomorrow. :)");
  };

  return (
    <div className={playerPlaying ? "trackBox playerPlaying" : "trackBox"}>
      <audio
        src={currentTrack.src}
        ref={audioRef}
        onLoadedMetadata={onLoadedMetadata}
        onEnded={handleNext}
      />
      <div className="audio-info">
        <div className="audio-image">
          {currentTrack.thumbnail ? (
            <div className="audio-image-inner">
              {/* <img
                src={currentTrack.thumbnail}
                alt="audio avatar"
                className="rotating2"
              /> */}

              <Clip
                url={currentTrack.thumbnail}
                url2={currentTrack.thumbnail2}
              />

              <div className="blackFade"></div>
            </div>
          ) : (
            <div className="icon-wrapper">
              <span className="audio-icon">
                <BsMusicNoteBeamed />
              </span>
            </div>
          )}
        </div>
        <div className="text">
          <div className="trackText">
            <p className="title">{currentTrack.title}</p>
            <p className="artist">{currentTrack.author}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DisplayTrack;
