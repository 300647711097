import { useRef, useState, useEffect } from "react";
import { tracks } from "../data/tracks";

import cover from "../data/joao3.mp4";
import cover2 from "../data/joao3.webm";

// import components
import DisplayTrack from "./DisplayTrack";
import Controls from "./Controls";
import ProgressBar from "./ProgressBar";
import TopBar from "./TopBar";
import Marquee from "./Marquee";
import Requests from "./Requests";

import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "GTM-TMMWPTBG",
};
TagManager.initialize(tagManagerArgs);

const AudioPlayer = () => {
  // states
  const [trackIndex, setTrackIndex] = useState(0);
  const [currentTrack, setCurrentTrack] = useState(tracks[trackIndex]);
  const [timeProgress, setTimeProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const [showRequests, setShowRequests] = useState(false);
  const [playerPlaying, setPlayerPlaying] = useState(false);

  // reference
  const audioRef = useRef();
  const progressBarRef = useRef();
  const videoRef2 = useRef();

  const handleToggleRequests = () => {
    setShowRequests(!showRequests);
  };
  const handleNext = () => {
    let ti = 0;

    if (trackIndex >= tracks.length - 1) {
      setTrackIndex(0);
      setCurrentTrack(tracks[0]);
    } else {
      setTrackIndex((prev) => prev + 1);
      setCurrentTrack(tracks[trackIndex + 1]);
      ti = trackIndex + 1;
    }
  };

  const secretSkipTo = () => {
    setTrackIndex(75);
    setCurrentTrack(tracks[75]);
    setPlayerPlaying(true);
  };

  useEffect(() => {
    //preloading image
    tracks.forEach((track) => {
      const img = new Image();
      img.src = track.thumbnail;
    });
  }, []);

  return (
    <>
      <TopBar {...{ playerPlaying }} />

      <video
        ref={videoRef2}
        autoPlay="autoplay"
        loop="loop"
        muted
        defaultMuted
        playsInline
        oncontextmenu="return false;"
        preload="auto"
        id="joaoVid"
      >
        <source src={cover} type="video/mp4" />
        <source src={cover2} type="video/webm" />
      </video>

      <div className="audio-player">
        <div className="inner">
          <DisplayTrack
            {...{
              currentTrack,
              audioRef,
              setDuration,
              progressBarRef,
              handleNext,
              handleToggleRequests,
              playerPlaying,
            }}
          />
          <Controls
            {...{
              audioRef,
              progressBarRef,
              duration,
              setTimeProgress,
              tracks,
              trackIndex,
              setTrackIndex,
              setCurrentTrack,
              handleNext,
              setPlayerPlaying,
              playerPlaying,
            }}
          />
          <ProgressBar
            {...{ progressBarRef, audioRef, timeProgress, duration }}
          />
        </div>
      </div>
      {showRequests && <Requests handleToggleRequests={handleToggleRequests} />}

      <Marquee handleSecret={secretSkipTo} />
    </>
  );
};
export default AudioPlayer;
